import { useDiem, useDiemStacked1, useDiemStacked2, useDiemStacked3, useDiemStacked4 } from "../../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default",
      },
      title: "Diem Association",
      uri: "/work/building-the-future-of-payments/",
      // dateGmt: "2020-12-23T09:05:36",
      slug: "building-the-future-of-payments",
      // modifiedGmt: "2021-05-18T21:11:03",
      seo: {
        canonical: "",
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Diem Association - Vivid",
        opengraphType: "article",
        title: "Diem Association - Vivid",
        twitterDescription: "",
        twitterTitle: "",
        twitterImage: null,
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#a193e2",
          backgroundGradientDark: "#624ebe",
          backgroundImagePosition: "bottom",
          backgroundSize: "contain",
          cta: null,
          ctaVariant: "light",
          description: "Helping Diem create and launch the next step in payment technology",
          descriptionColor: "#ffffff",
          title: "Building the future of payments",
          titleColor: "#ffffff",
          rightImage: {
            altText: "",
            useImage: useDiem,
            localFile: {
              publicURL: "/static/4ad17be80946892d366190d64147df31/Diem-Header-cutout-2-1.png",
              extension: "png",
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEfklEQVQ4yzXTe0yTVxQA8NN2GijFFVDaUqGAtkCh/VrkzVeg5am8MioIQlkQLBnDqRRR0TlgjPezhuGkTEXC4oZsURiZ/oFzIEwjoiPbzCY6zZyDZI7pYNL2O/so8STnv3t+95zccwHoqKqpBESEuzO3GNbn9+Fmo87uaNuZG4e7huZ2H6gxJe58u0Ak2Cihj66LzHwf1tlHgEhkR9cACIRuwN7AB2CEQEBAANgiM/MtG7gaK9Sic1dX14nvZ+7jnScv8crsPJ4d+xlr+65YDrb2z8Rp3zG58kPyQoI3i+kSpttmEWxwEgEwSSgtLV0DNer4VZC1it67M3fh0aPfcWn5b8vS0j+Wf5dfmReeL1sezv+Hs8+W8ZPBaYzWtGJoUMWKVJZzKzyCFBHKbaAMTGIUFhaugRu5YijJucbamzEO28mWLwuzTNhed2Xl6sgsPvx1nlpcfEmtvHpFWVaslj+fvTAvzC9ZBvp/ovz9DCiWhsQ4CwjYIklhuru7r4GuzhLQxoywdmnGICaw/mIU0YSkrNksdEqkSIWB2pf/OdZ+cIYa/WYMRy/fov54ukBNTT62BhGV6OmjUNk7+4GXOIW5adOm16AP7FJ/zcqNGwO1sn4oLqgFE4I6zR4CEpViPUYHnESxcDfyhXL0lcTjpYtT1PVvH1sJWR16S8NUDvwg8PTNXAPbmptAJJRDRuQIK0d9HSL9PxyKDWzGuG0dZi+hmgqRvos7wrpQE9iIft5a9Nioxb7T49SNyd+s3j7HURIQrnISBoOXr5bJ4/FosKkRGmprmR11X63vbX0MB4o++0KX1osRvo1mznoCpR4FmJ9+js4e1KWfxfigTjzf8x01SY+8WVKOW/0JFdfND0Q+O9bAj6qroNtohGOHK2yrM/fgl9FHc09xdnrebGzvo/brT2NWggljiAZMJttRJaunzpvGqWsTT6wC8QFUKAjSw9MHJP5JTC6XC1BfW8P+2NhJ0ll9sr1t+nC5YWlv0R6LLj+HMho7qImJCWr23gO8Of6E6umcpPTZA1S/6Zr18tUfzFzPQpTJFWpY7wIBiqg3bA9Soi9SHTtSMU13ii0N9dhcX4cVhjKMIknkcDgWNpttlsmkqxdYe8+cMt++fcdadaIaYzUalBNhLwQ8/lZthhbUMVFrn2N46MKqa0fI/P2yd2rzyg++Z6o6XjldXLRnOT01GcNCg9HbyxNdnJ3R3s4eBQIebvH2+pFtb3dpA8euv7hYP2UwGMbS0lIzu7tPARw9VGbrdHdWBlwavPD6CzqQ4aHypPjYgsT42E/j49R3o1WRcyHBgWcl4q05Tk7cAUdHzl+Ojo64PSlxLjExoZsg5KSttsfYBFGRYVC+v5RxznSaOTw0yFwVS/V7oPrYEXoL6kAqEb8ZqJALBgd6ITcrFXg8Vz8a1bkJ+En0Uc7w8GXIz8+DlJQUAKVSCS4uLkDfBg4ObFu3edk7obS4iFGiL2Tm52az6o6UQWXZPogMD2WlJScx+DxXoDFQKuS2iSoOlbN0ulyGRqOB/wHntvDQDKZzAAAAAABJRU5ErkJggg==",
                  aspectRatio: 1.3245033112582782,
                  src: "/static/4ad17be80946892d366190d64147df31/f9ff4/Diem-Header-cutout-2-1.png",
                  srcSet:
                    "/static/4ad17be80946892d366190d64147df31/5224a/Diem-Header-cutout-2-1.png 200w,\n/static/4ad17be80946892d366190d64147df31/d786d/Diem-Header-cutout-2-1.png 400w,\n/static/4ad17be80946892d366190d64147df31/f9ff4/Diem-Header-cutout-2-1.png 800w,\n/static/4ad17be80946892d366190d64147df31/73f08/Diem-Header-cutout-2-1.png 1200w,\n/static/4ad17be80946892d366190d64147df31/ac5f8/Diem-Header-cutout-2-1.png 1466w",
                  srcWebp:
                    "/static/4ad17be80946892d366190d64147df31/b0751/Diem-Header-cutout-2-1.webp",
                  srcSetWebp:
                    "/static/4ad17be80946892d366190d64147df31/9e195/Diem-Header-cutout-2-1.webp 200w,\n/static/4ad17be80946892d366190d64147df31/40a1d/Diem-Header-cutout-2-1.webp 400w,\n/static/4ad17be80946892d366190d64147df31/b0751/Diem-Header-cutout-2-1.webp 800w,\n/static/4ad17be80946892d366190d64147df31/a7c53/Diem-Header-cutout-2-1.webp 1200w,\n/static/4ad17be80946892d366190d64147df31/0d7e6/Diem-Header-cutout-2-1.webp 1466w",
                  sizes: "(max-width: 800px) 100vw, 800px",
                  presentationWidth: 800,
                },
              },
            },
          },
          backgroundImage: {
            altText: "",
            name: "/diem-waves.svg",
            localFile: {
              publicURL: "/static/a06e50ac15bed4f13d563a15111a41d4/diem-waves.svg",
              extension: "svg",
              childImageSharp: null,
            },
          },
          overlapSection: null,
        },
        {
          __typename: "Overview",
          client: "Diem",
          description:
            "The Diem Association, a blockchain-based global payment system, partnered with Vivid in 2019 to help bring their ambitious vision to life. In the face of intense regulatory oversight, they needed to package a paradigm-shifting technology to provide maximum value to their target users while remaining within the bounds of international financial law. <br />\r\n<br />\r\nFrom strategic guidance and project management to full-stack development and creative support, we’ve been with Diem at every stage of their journey.",
          industry: "Fintech",
          solutions:
            "Strategy, Architecture, Engineering, Branding, Design, Copywriting, Project Management",
          title: "Overview",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "Diem_Success-Story_Website_3 2",
                useImage: useDiemStacked1,
                localFile: {
                  publicURL:
                    "/static/0a9535ed557b63632653db5582636dd6/Diem_Success-Story_Website_3-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEAf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAZaJmHMD/8QAGhABAAIDAQAAAAAAAAAAAAAAAQACAxIjQv/aAAgBAQABBQKwtvHEmS+qZFtsT//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAwEBPwHEf//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQIBAT8Brpp//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAIRIQExMmGRkv/aAAgBAQAGPwLS4LLywtL0RC3wbE8n/8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAITFBYbH/2gAIAQEAAT8hBFyBewC9mscSLB7EKQLnomBRKdhZQPNP/9oADAMBAAIAAwAAABBgD//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QiwKEf//EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAECAQE/EBkz/8QAGxABAQADAQEBAAAAAAAAAAAAAREAITFBwdH/2gAIAQEAAT8QAWGvTyywuIVwUsND38yKCA6b5j7jDkSSXmHYkyCoM33zJbDXuH//2Q==",
                      aspectRatio: 1.6129032258064515,
                      src: "/static/0a9535ed557b63632653db5582636dd6/a1eb1/Diem_Success-Story_Website_3-2.jpg",
                      srcSet:
                        "/static/0a9535ed557b63632653db5582636dd6/4fa6c/Diem_Success-Story_Website_3-2.jpg 200w,\n/static/0a9535ed557b63632653db5582636dd6/64b17/Diem_Success-Story_Website_3-2.jpg 400w,\n/static/0a9535ed557b63632653db5582636dd6/a1eb1/Diem_Success-Story_Website_3-2.jpg 800w,\n/static/0a9535ed557b63632653db5582636dd6/a6c62/Diem_Success-Story_Website_3-2.jpg 1200w,\n/static/0a9535ed557b63632653db5582636dd6/70ff9/Diem_Success-Story_Website_3-2.jpg 1600w,\n/static/0a9535ed557b63632653db5582636dd6/de26c/Diem_Success-Story_Website_3-2.jpg 2246w",
                      srcWebp:
                        "/static/0a9535ed557b63632653db5582636dd6/b0751/Diem_Success-Story_Website_3-2.webp",
                      srcSetWebp:
                        "/static/0a9535ed557b63632653db5582636dd6/9e195/Diem_Success-Story_Website_3-2.webp 200w,\n/static/0a9535ed557b63632653db5582636dd6/40a1d/Diem_Success-Story_Website_3-2.webp 400w,\n/static/0a9535ed557b63632653db5582636dd6/b0751/Diem_Success-Story_Website_3-2.webp 800w,\n/static/0a9535ed557b63632653db5582636dd6/a7c53/Diem_Success-Story_Website_3-2.webp 1200w,\n/static/0a9535ed557b63632653db5582636dd6/80926/Diem_Success-Story_Website_3-2.webp 1600w,\n/static/0a9535ed557b63632653db5582636dd6/05f71/Diem_Success-Story_Website_3-2.webp 2246w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "Diem_Success-Story_Mobile_8 2",
                useImage: useDiemStacked2,
                localFile: {
                  publicURL:
                    "/static/c104fabaa16e4e7449b21f9cc0526bab/Diem_Success-Story_Mobile_8-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAGClKmGGSH/xAAcEAABAwUAAAAAAAAAAAAAAAABAgMRABASEyH/2gAIAQEAAQUCpp3SsmVScem3/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERIRD/2gAIAQEABj8CN1DclZFO/wD/xAAbEAEBAQACAwAAAAAAAAAAAAABEQAQITFRYf/aAAgBAQABPyHWIUPBiKgiHrFAfbO9YrOP/9oADAMBAAIAAwAAABDw/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQMBAT8QDZ//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBJ/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITEQQWGRof/aAAgBAQABPxA3CwRyIqj17+RLyitoW6xDCViLofMy/QpvtxXH/9k=",
                      aspectRatio: 1.3986013986013985,
                      src: "/static/c104fabaa16e4e7449b21f9cc0526bab/a1eb1/Diem_Success-Story_Mobile_8-2.jpg",
                      srcSet:
                        "/static/c104fabaa16e4e7449b21f9cc0526bab/4fa6c/Diem_Success-Story_Mobile_8-2.jpg 200w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/64b17/Diem_Success-Story_Mobile_8-2.jpg 400w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/a1eb1/Diem_Success-Story_Mobile_8-2.jpg 800w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/a6c62/Diem_Success-Story_Mobile_8-2.jpg 1200w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/70ff9/Diem_Success-Story_Mobile_8-2.jpg 1600w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/de26c/Diem_Success-Story_Mobile_8-2.jpg 2246w",
                      srcWebp:
                        "/static/c104fabaa16e4e7449b21f9cc0526bab/b0751/Diem_Success-Story_Mobile_8-2.webp",
                      srcSetWebp:
                        "/static/c104fabaa16e4e7449b21f9cc0526bab/9e195/Diem_Success-Story_Mobile_8-2.webp 200w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/40a1d/Diem_Success-Story_Mobile_8-2.webp 400w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/b0751/Diem_Success-Story_Mobile_8-2.webp 800w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/a7c53/Diem_Success-Story_Mobile_8-2.webp 1200w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/80926/Diem_Success-Story_Mobile_8-2.webp 1600w,\n/static/c104fabaa16e4e7449b21f9cc0526bab/05f71/Diem_Success-Story_Mobile_8-2.webp 2246w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
        {
          __typename: "Quote",
          authorAndTitleCompany: "Dante Disparte, Executive Vice President at Diem Association",
          quoteText:
            "I consider Vivid an integral extension of my team. The professionalism and talent that exists within the team is unmatched and something that will help us as we continue to grow and realize the full potential of the Diem project. I'd go to battle with Vivid by my side any day.",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "Diem_Success-Story_docs_1 2",
                useImage: useDiemStacked3,
                localFile: {
                  publicURL:
                    "/static/587becf338b6004abfa675d02ac5aaf5/Diem_Success-Story_docs_1-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAd8mlYrkQN//xAAZEAEBAQEBAQAAAAAAAAAAAAACAQMRBBP/2gAIAQEAAQUC2SzJTWsPJ6TPk7M1dVz/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAECMSEDERIiQf/aAAgBAQAGPwKLjt2YoajTLMr05QiqFWUf/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAEhETFBgbH/2gAIAQEAAT8htC8h2j0mJj0fBhUhCcrePQg3Gpw//9oADAMBAAIAAwAAABAHD//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCGn//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxCsf//EAB4QAQABBAIDAAAAAAAAAAAAAAERACExQVFhgZGx/9oACAEBAAE/EFgnhQNtRxmh6aQmAcx83QFGUlRA0Bw1TEHXqralQmGE81aWq/CVtnqv/9k=",
                      aspectRatio: 1.5037593984962405,
                      src: "/static/587becf338b6004abfa675d02ac5aaf5/a1eb1/Diem_Success-Story_docs_1-2.jpg",
                      srcSet:
                        "/static/587becf338b6004abfa675d02ac5aaf5/4fa6c/Diem_Success-Story_docs_1-2.jpg 200w,\n/static/587becf338b6004abfa675d02ac5aaf5/64b17/Diem_Success-Story_docs_1-2.jpg 400w,\n/static/587becf338b6004abfa675d02ac5aaf5/a1eb1/Diem_Success-Story_docs_1-2.jpg 800w,\n/static/587becf338b6004abfa675d02ac5aaf5/a6c62/Diem_Success-Story_docs_1-2.jpg 1200w,\n/static/587becf338b6004abfa675d02ac5aaf5/70ff9/Diem_Success-Story_docs_1-2.jpg 1600w,\n/static/587becf338b6004abfa675d02ac5aaf5/de26c/Diem_Success-Story_docs_1-2.jpg 2246w",
                      srcWebp:
                        "/static/587becf338b6004abfa675d02ac5aaf5/b0751/Diem_Success-Story_docs_1-2.webp",
                      srcSetWebp:
                        "/static/587becf338b6004abfa675d02ac5aaf5/9e195/Diem_Success-Story_docs_1-2.webp 200w,\n/static/587becf338b6004abfa675d02ac5aaf5/40a1d/Diem_Success-Story_docs_1-2.webp 400w,\n/static/587becf338b6004abfa675d02ac5aaf5/b0751/Diem_Success-Story_docs_1-2.webp 800w,\n/static/587becf338b6004abfa675d02ac5aaf5/a7c53/Diem_Success-Story_docs_1-2.webp 1200w,\n/static/587becf338b6004abfa675d02ac5aaf5/80926/Diem_Success-Story_docs_1-2.webp 1600w,\n/static/587becf338b6004abfa675d02ac5aaf5/05f71/Diem_Success-Story_docs_1-2.webp 2246w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "Diem_Success-Story_presentations_1 2",
                useImage: useDiemStacked4,
                localFile: {
                  publicURL:
                    "/static/1fb369a61dfd651b85bea38ec8d8aa68/Diem_Success-Story_presentations_1-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABa0nshmGj/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDABET/9oACAEBAAEFAouuALMjz5SIWM5Dy7v/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwFD/8QAIBAAAAUDBQAAAAAAAAAAAAAAAAECERIDITEQIiNRYf/aAAgBAQAGPwI0ExeiVPDXNQ5EyV2J5Vkbr2fT/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIUExgVFhcf/aAAgBAQABPyHAB33JeevIr+RXCGtrj5r0CPyNmDRFPqf/2gAMAwEAAgADAAAAEMjf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EDV//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERUWH/2gAIAQIBAT8Q2RNZ/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWFxkf/aAAgBAQABPxDUrpsrClHv6zXGOQqhOk8LxfhiujC1A6lSHo1iy0CZydB1iJ26krNTvrn8xG0HgM//2Q==",
                      aspectRatio: 1.550387596899225,
                      src: "/static/1fb369a61dfd651b85bea38ec8d8aa68/a1eb1/Diem_Success-Story_presentations_1-2.jpg",
                      srcSet:
                        "/static/1fb369a61dfd651b85bea38ec8d8aa68/4fa6c/Diem_Success-Story_presentations_1-2.jpg 200w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/64b17/Diem_Success-Story_presentations_1-2.jpg 400w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/a1eb1/Diem_Success-Story_presentations_1-2.jpg 800w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/a6c62/Diem_Success-Story_presentations_1-2.jpg 1200w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/70ff9/Diem_Success-Story_presentations_1-2.jpg 1600w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/24336/Diem_Success-Story_presentations_1-2.jpg 2244w",
                      srcWebp:
                        "/static/1fb369a61dfd651b85bea38ec8d8aa68/b0751/Diem_Success-Story_presentations_1-2.webp",
                      srcSetWebp:
                        "/static/1fb369a61dfd651b85bea38ec8d8aa68/9e195/Diem_Success-Story_presentations_1-2.webp 200w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/40a1d/Diem_Success-Story_presentations_1-2.webp 400w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/b0751/Diem_Success-Story_presentations_1-2.webp 800w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/a7c53/Diem_Success-Story_presentations_1-2.webp 1200w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/80926/Diem_Success-Story_presentations_1-2.webp 1600w,\n/static/1fb369a61dfd651b85bea38ec8d8aa68/adb2e/Diem_Success-Story_presentations_1-2.webp 2244w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
